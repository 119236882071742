import * as React from "react";

import type { ErrorObject } from "../../../../../../../server/types/error";
import type { TrueAdCopySimpleActivity } from "../../../../../types/ad-copy/activity";
import { TextRow } from "../../../../parts/input-row";

const key = "currentProductTrialSalesVolume";

type Key = typeof key;

type Value = TrueAdCopySimpleActivity[Key];

type Props = {
	errors: ErrorObject | undefined;
	onChangeActivity: (name: Key, value: Value) => void;
	value: Value;
};

export const CurrentProductTrialSalesVolumeInput = React.memo((props: Props) => {
	const { errors, onChangeActivity, value } = props;

	return (
		<TextRow
			errors={errors}
			id={key}
			name={key}
			onChange={(value) => {
				// NOTE: undefined だと Optional のフィールドが更新されないので、null で更新する
				onChangeActivity(key, value == null ? null : value);
			}}
			rowName={
				<div>
					現行品に相当する製品の上市後1年間
					<br />
					トライアル売上数量(SLI,SCIに基づく)（万個）
				</div>
			}
			type="decimal"
			value={value?.toString() ?? ""}
		/>
	);
});
