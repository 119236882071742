import * as React from "react";

import type { CategoryType } from "../../../../../../../server/models/category";

export function emptyToUndefined<T>(value: T | "") {
	if (value === "") {
		return;
	}

	return value;
}

export function isSkincare(type: CategoryType) {
	return type === "skincare";
}

export function highlight非(text: string | undefined) {
	if (text == null) {
		return "";
	}

	// eslint-disable-next-line max-params
	const parts = text.split("非").reduce((acc, part, index, array) => {
		acc.push(part);

		if (index < array.length - 1) {
			acc.push(
				<span
					key={index}
					style={{
						color: "red",
						fontWeight: "bold",
					}}
				>
					非
				</span>,
			);
		}

		return acc;
	}, [] as React.ReactNode[]);

	return parts;
}
