import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";

import { strategyTargetGroupLabels } from "../../../../../../../label/activity";
import type { ErrorObject } from "../../../../../../../react-components/lu-component/src/index";
import { Radios } from "../../../../../../../react-components/lu-component/src/index";
import { AccordionForm } from "../../../../parts/accodion-form";
import { ActivityCheckboxes } from "../../../../parts/checkboxes";
import { highlight非 } from "./utils";
import { categoryOrAndOptions, StrategyGroup } from "../../../../../../../server/models/activity";
import { Option } from "../../../../../../../server/types/request";

const key = "nonPurchaseCategoryConfig";

type Props = {
	errors: ErrorObject | undefined;
	onChange: (name: keyof StrategyGroup, value: any) => void;
	strategyGroup: StrategyGroup;
	surveyChoiceOptions: Option[];
};

export const NonPurchaseCategoryConfigForm = React.memo((props: Props) => {
	const { errors, onChange, strategyGroup, surveyChoiceOptions } = props;

	const nonPurchaseCategoryConfigError = errors?.[key];

	const categoryOrAndError = React.useMemo(() => {
		if (nonPurchaseCategoryConfigError == null || typeof nonPurchaseCategoryConfigError === "string") {
			return;
		}

		const error = nonPurchaseCategoryConfigError["categoryOrAnd"];

		if (typeof error === "string") {
			return error;
		}
	}, [nonPurchaseCategoryConfigError]);

	const nonPurchaseCategoryConfig = strategyGroup[key];

	return (
		<Row>
			<FormGroup as={Col} md={10}>
				<AccordionForm
					defaultShow={nonPurchaseCategoryConfig != null}
					eventKey={key}
					title={highlight非(strategyTargetGroupLabels[key])}
				>
					<>
						<Row>
							<Col md={4}>
								<Radios
									checked={nonPurchaseCategoryConfig?.categoryOrAnd ?? ""}
									choices={[
										{
											label: "設定しない",
											value: "",
										},
										...categoryOrAndOptions,
									]}
									name={`${key}_categoryOrAnd`}
									onChange={(value) => {
										onChange(
											key,
											value === ""
												? undefined
												: {
														...nonPurchaseCategoryConfig,
														categoryOrAnd: value as "And" | "Or",
														surveyChoices: nonPurchaseCategoryConfig?.surveyChoices ?? [],
												  },
										);
									}}
								/>
							</Col>
						</Row>

						{nonPurchaseCategoryConfig?.categoryOrAnd && (
							<Row>
								<Col md={3} style={{ marginBottom: "0.6em" }} />

								<Col md={12}>
									<ActivityCheckboxes
										checkeds={nonPurchaseCategoryConfig.surveyChoices.map((c) => c.value)}
										choices={surveyChoiceOptions}
										disabledChoices={strategyGroup.category?.map((x) => x.surveyChoice)}
										name={`${key}_surveyChoices`}
										onChange={(nextValues) => {
											onChange(key, {
												...nonPurchaseCategoryConfig,
												surveyChoices: surveyChoiceOptions.filter((x) => nextValues.includes(x.value)),
											});
										}}
									/>

									{categoryOrAndError && (
										<Feedback style={{ display: "block" }} type="invalid">
											{categoryOrAndError}
										</Feedback>
									)}
								</Col>
							</Row>
						)}
					</>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
