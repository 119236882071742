import * as React from "react";
import { FormCheck } from "react-bootstrap";

import type { ErrorObject } from "../../../../../../../server/types/error";
import type { TrueAdCopyActivity } from "../../../../../types/ad-copy/activity";
import { InputRow } from "../../../../parts/input-row";

const key = "isDemandForecastNeeded";

type Key = typeof key;

type Props = {
	checked: boolean;
	errors: ErrorObject | undefined;
	onChangeActivity: (name: Key, value: TrueAdCopyActivity[Key]) => void;
};

const choices = [
	{
		label: "あり",
		value: true,
	},
	{
		label: "なし",
		value: false,
	},
] as const;

export const IsDemandForecastEnabledSwitch = React.memo((props: Props) => {
	const { checked, errors, onChangeActivity } = props;

	return (
		<InputRow errors={errors} name={key} rowName="需要予測の有無">
			{choices.map(({ label, value }) => (
				<FormCheck
					key={String(value)}
					checked={checked === value}
					id={`${key}-${value}`}
					inline
					label={label}
					name={key}
					onChange={() => {
						onChangeActivity(key, value);
					}}
					type="radio"
				/>
			))}
		</InputRow>
	);
});
