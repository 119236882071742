import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import type { AdCopyStrategyGroupCategory } from "../../../../../../server/models/ad-copy/activity";
import { AdCopyFacewashFunction, AdCopySensitiveSkin } from "../../../../../../server/models/ad-copy/activity";
import type { Brand } from "../../../../../../server/models/brand";
import {
	canSetFacewachFunction,
	type Category,
	isCleansing,
	isFaceWash,
} from "../../../../../../server/models/category";
import type { AdCopyActivityCreateAction } from "../../../../types/actions";
import type {
	TrueAdCopyActivity,
	TrueAdCopySimpleActivity,
	TrueAdCopyStrategyGroup,
} from "../../../../types/ad-copy/activity";
import type { MessageInfo } from "../../../../types/info";

type State = {
	accounts: AccountWithoutPassword[];
	activity?: TrueAdCopySimpleActivity;
	brands: Brand[];
	cantEditMessage?: string;
	categories: Category[];
	info: MessageInfo;
	loading: boolean;
	prompt: boolean;
	strategyTargetGroup: TrueAdCopyStrategyGroup;
};

const initialStrategyTargetGroup: TrueAdCopyStrategyGroup = {
	age: { from: undefined, to: undefined },
	categoryOrAnd: "Or",
};

export const initState: State = {
	accounts: [],
	brands: [],
	categories: [],
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
	prompt: false,
	strategyTargetGroup: initialStrategyTargetGroup,
};

const makeInitActivity = (initActivity: TrueAdCopyActivity) => {
	const { strategyTargetGroup, ...activity } = initActivity;

	return {
		activity: {
			...activity,
			actionStandards:
				activity.actionStandards == null || activity.actionStandards.length <= 0
					? ["", "", ""]
					: activity.actionStandards,
			isDemandForecastNeeded: activity.isDemandForecastNeeded ?? false,
		} satisfies TrueAdCopyActivity,
		strategyTargetGroup: strategyTargetGroup ?? initialStrategyTargetGroup,
	};
};

const changeActivity = <T extends keyof TrueAdCopySimpleActivity>({
	brands,
	name,
	state,
	value,
}: {
	brands: Brand[];
	name: T;
	state: State;
	value: TrueAdCopySimpleActivity[T];
}) => {
	const activity = {
		...state.activity,
		[name]: value,
	} as TrueAdCopySimpleActivity;

	// 選択ブランドが変わったら、ブランド名を削除する。
	if (name === "brandId") {
		delete activity.brandName;
	}

	// 認知率はブランドとカテゴリが出揃ったタイミングで取得。
	if ((name === "brandId" || name === "category") && activity.brandId && activity.category) {
		const { brandId, category } = activity;

		// カテゴリ x ブランドで見つけることができるものは認知率をセットする。
		const selectedBrand = brands.find((b) => b._id?.toString() === brandId.toString());

		// nBrandが0は他ブランド
		const filteredCategoryRecognition = category.categoryRecognition.filter(
			(cRecognition) => cRecognition.nBrand !== 0 && cRecognition.nBrand === selectedBrand?.nBrand,
		);

		if (filteredCategoryRecognition.length) {
			activity.goalRecognitionRate = Math.round(
				Math.max(...filteredCategoryRecognition.map((cRecognition) => cRecognition.gAwareness)),
			);
		} else {
			activity.goalRecognitionRate = undefined;
		}
	}

	if (name === "category") {
		const category = activity.category;

		if (!state.activity?.category || category?.gender !== state.activity.category.gender) {
			state.strategyTargetGroup = {
				...state.strategyTargetGroup,
				category: [],
			};
		}

		if (category == null || !category.adCopyCanDemandForecastNeeded) {
			activity.isDemandForecastNeeded = false;
		}
	}

	return { ...state, activity };
};

const deleteFacewashFunction = (
	categories: AdCopyStrategyGroupCategory[] | undefined,
	facewashFunction: TrueAdCopyStrategyGroup["facewashFunction"],
) => {
	if (!facewashFunction) {
		return false;
	}

	if (facewashFunction === AdCopyFacewashFunction.洗顔機能のみの商品.toString()) {
		return categories?.every(({ category }) => !isFaceWash(category));
	}

	if (facewashFunction === AdCopyFacewashFunction.メイク落とし機能のみの商品.toString()) {
		return categories?.every(({ category }) => !isCleansing(category));
	}

	return categories?.every(({ category }) => !canSetFacewachFunction(category));
};

const changeStrategy = (strategyGroup: TrueAdCopyStrategyGroup) => {
	// 敏感肌
	if (
		strategyGroup.sensitiveSkin == null ||
		strategyGroup.sensitiveSkin.length === 0 ||
		strategyGroup.sensitiveSkin.includes(
			AdCopySensitiveSkin.敏感肌ではない.toString() as keyof typeof AdCopySensitiveSkin,
		)
	) {
		delete strategyGroup.sensitiveSkinFrequency;
	}

	// チャネルはカテゴリのチェック状況に応じて変動するので、チェックが無くなったら削除する必要がある。
	if (strategyGroup.category?.length === 0) {
		strategyGroup.channel = [];
	}

	// ファウンデーションタイプはチェック状況に応じて削除する必要がある。
	if (strategyGroup.category?.every(({ category }) => category.type !== "liquid-foundation")) {
		delete strategyGroup.foundationType;
	}

	// 洗顔料の条件はチェック状況に応じて削除する必要がある
	if (deleteFacewashFunction(strategyGroup.category, strategyGroup.facewashFunction)) {
		delete strategyGroup.facewashFunction;
	}

	// 口紅・リップグロス
	if (strategyGroup.category?.every(({ category }) => category.type !== "lipstick")) {
		delete strategyGroup.lipstickType;
	}

	// アイシャドー
	if (strategyGroup.category?.every(({ category }) => category.type !== "eyeShadow")) {
		delete strategyGroup.eyeshadowType;
	}

	// ジプシー
	if (!strategyGroup.category?.some((x) => x.surveyChoice === strategyGroup.gypsy?.surveyChoice.value)) {
		delete strategyGroup.gypsy;
	}

	// ジプシーで選ばれたカテゴリ
	const selectedGypsyStrategyGroupCategory = strategyGroup.category?.find(
		(x) => x.surveyChoice === strategyGroup.gypsy?.surveyChoice.value,
	);

	// ジプシーのカテゴリタイプの矛盾解消
	if (
		strategyGroup.gypsy?.beautyType &&
		!selectedGypsyStrategyGroupCategory?.beautyType?.includes(strategyGroup.gypsy.beautyType)
	) {
		delete strategyGroup.gypsy.beautyType;
	}

	// ジプシーの洗顔機能の矛盾解消
	if (
		strategyGroup.gypsy?.facewashFunction &&
		strategyGroup.facewashFunction !== strategyGroup.gypsy.facewashFunction
	) {
		delete strategyGroup.gypsy.facewashFunction;
	}

	// ジプシーのファンデーションタイプの矛盾解消
	if (
		strategyGroup.gypsy?.foundationType &&
		!strategyGroup.foundationType?.includes(strategyGroup.gypsy.foundationType)
	) {
		delete strategyGroup.gypsy.foundationType;
	}

	// ジプシーの口紅・リップグロスの矛盾解消
	if (strategyGroup.gypsy?.lipstickType && !strategyGroup.lipstickType?.includes(strategyGroup.gypsy.lipstickType)) {
		delete strategyGroup.gypsy.lipstickType;
	}

	// ジプシーのアイシャドーの矛盾解消
	if (strategyGroup.gypsy?.eyeshadowType && !strategyGroup.eyeshadowType?.includes(strategyGroup.gypsy.eyeshadowType)) {
		delete strategyGroup.gypsy.eyeshadowType;
	}

	return strategyGroup;
};

export const reducer = (state: State, action: AdCopyActivityCreateAction): State => {
	switch (action.type) {
		case "loadActivity":
			return {
				...state,
				...action.payload,
				loading: false,
				...makeInitActivity(action.payload.activity),
			};

		case "updateActivity":
			return {
				...state,
				info: {
					isSuccess: true,
					message: `${state.activity?._id ? "更新" : "作成"}が完了しました。`,
				},
				loading: false,
				prompt: false,
				...makeInitActivity(action.payload),
			};

		case "changeActivity":
			return {
				...changeActivity({ brands: state.brands, name: action.payload.name, state, value: action.payload.value }),
				prompt: true,
			};

		case "changeStrategyGroup":
			return {
				...state,
				strategyTargetGroup: changeStrategy({
					...state.strategyTargetGroup,
					[action.payload.name]: action.payload.value,
				} as TrueAdCopyStrategyGroup),
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
