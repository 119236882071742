import * as React from "react";
import {
	Checkboxes,
	Radios,
	Option,
	Choice,
	ErrorObject,
} from "../../../../../../../react-components/lu-component/src/index";
import styled from "styled-components";
import { FormGroup, Col, Row, Alert, Badge } from "react-bootstrap";
import {
	Category,
	canSetFacewachFunction,
	fromPriceOptions,
	isCleansing,
	isFaceWash,
	toPriceOptions,
} from "../../../../../../../server/models/category";
import { RangeSelect, RangeValue } from "../../../../parts/range";
import { SubTitle, AccordionForm } from "../../../../parts/accodion-form";

import {
	StrategyGroup,
	StrategyGroupCategory,
	marriedOptions,
	childrenOptions,
	occupationOptions,
	skinAttributeExcludeOtherOptions,
	sensitiveSkinOptions,
	channelOptions,
	beautyTypeOptions,
	facewashFunctionOptions,
} from "../../../../../../../server/models/activity";
import { strategyTargetGroupLabels, strategyGroupCategoryLabels } from "../../../../../../../label/activity";
import { Gender } from "../../../../../../../server/models/shared";
import { FoundationTypeCheckboxes } from "./foundation-type-checkboxes";
import { SensitiveSkinFrequencyCheckboxes } from "./sensitive-skin-frequency-checkboxes";
import { SkinProblemFrequencyCheckboxes } from "./skin-problem-frequency-checkboxes";
import { NonPurchaseCategoryConfigForm } from "./non-purchase-category-config-form";
import { LipstickTypeCheckboxes } from "./lipstick-type-checkboxes";
import { EyeshadowTypeCheckboxes } from "./eyeshadow-type-checkboxes";
import { UsedBrandForms } from "./used-brand-forms";
import { UnusedBrandForms } from "./unused-brand-forms";
import { AestheticMedicineExperienceCheckboxes } from "./aesthetic-medicine-experience-checkboxes";
import { GypsyForms } from "./gypsy-forms";
import { CategoryForm } from "./category-form";

const FormArea = styled.div`
	& .form-label {
		margin-bottom: 0.1em;
	}
	& .form-group {
		margin-bottom: 0.2em;
	}
`;

export type StrategicTargetFormProps = {
	gender?: keyof typeof Gender;
	strategyGroup: StrategyGroup;
	onChange: (name: keyof StrategyGroup, value: any) => void;
	categories: Category[];
	errors?: ErrorObject;
};

export const StrategicTargetForm = React.memo((props: StrategicTargetFormProps) => {
	const { gender, strategyGroup, onChange, categories, errors } = props;
	const surveyChoiceOptions = React.useMemo<Option[]>(() => {
		if (!gender) return [];
		return categories.reduce((a, b) => {
			if (b.gender !== gender) return a;
			a = a.concat(b.surveyChoice);
			console.log(b, gender);
			return a;
		}, []);
	}, [categories, gender]);
	const categoryMap = React.useMemo<{ [key: string]: Category }>(() => {
		return categories.reduce((a, b) => {
			return { ...a, [b._id.toString()]: b };
		}, {});
	}, [categories]);
	const surveyChoiceMap = React.useMemo(() => {
		if (!gender) return;
		return categories.reduce((a, b) => {
			if (gender !== b.gender) return a;
			b.surveyChoice.forEach((sc) => {
				a.set(sc.value, sc.label);
			});
			return a;
		}, new Map<number, string>());
	}, [categories, gender]);

	const skincateCategoryGroups = React.useMemo(() => {
		if (!strategyGroup.category || strategyGroup.category.length === 0) return [];
		return strategyGroup.category.filter((category) => {
			const id = category.category._id.toString();
			if (!(id in categoryMap)) return false;
			const { type } = categoryMap[id];
			return type === "skincare";
		});
	}, [strategyGroup.category, categoryMap]);

	const filterFacewashFunctionOptions = React.useMemo(() => {
		if (!strategyGroup.category || strategyGroup.category.length === 0) return undefined;
		const categories = strategyGroup.category.filter((category) => {
			const id = category.category._id.toString();
			if (!(id in categoryMap)) return false;
			const cat = categoryMap[id];
			return canSetFacewachFunction(cat);
		});
		if (!categories.length) return undefined;

		return facewashFunctionOptions.filter(({ value }) => {
			if (value.toString() === "3") return false;

			return categories.some((cate) => {
				if (value === "0") return isFaceWash(cate.category);
				if (value === "1") return isCleansing(cate.category);
				return true;
			});
		}) as Option[];
	}, [strategyGroup, categoryMap]);

	const facewashFunctionChecked = React.useMemo(() => {
		if (!filterFacewashFunctionOptions) return undefined;
		if (!strategyGroup.facewashFunction) return undefined;
		// 1~3は一つにまとめる
		return strategyGroup.facewashFunction;
	}, [strategyGroup, filterFacewashFunctionOptions]);

	const onChangeStrategyGroupCategory = React.useCallback(
		(surveyChoice: number, name: keyof StrategyGroupCategory, value: any) => {
			const values = strategyGroup.category.map((category) => {
				if (category.surveyChoice === surveyChoice) {
					return { ...category, [name]: value };
				}
				return { ...category };
			});
			onChange("category", values);
		},
		[onChange, strategyGroup],
	);
	return (
		<FormArea>
			<Row>
				<Col md={{ offset: 2, span: 8 }}>
					<Alert variant={"warning"}>
						<li>未選択の場合は、条件不問になります。</li>
						<li>設問間はすべてand条件になります。</li>
						<li>選択肢間はすべてor条件になります。</li>※ 「過去１年購入&使用カテゴリ」はor/andを選べます
					</Alert>
				</Col>
			</Row>
			<Row>
				<FormGroup as={Col} md={5} style={{ marginBottom: "20px" }}>
					<SubTitle>
						{strategyTargetGroupLabels.age}
						<Badge pill bg={"danger"} style={{ marginLeft: "10px" }}>
							必須
						</Badge>
					</SubTitle>
					<div style={{ marginLeft: "15px" }}>
						<RangeSelect
							fromOptions={[
								{ value: 15, label: "15歳" },
								{ value: 20, label: "20歳" },
								{ value: 25, label: "25歳" },
								{ value: 30, label: "30歳" },
								{ value: 35, label: "35歳" },
								{ value: 40, label: "40歳" },
								{ value: 45, label: "45歳" },
								{ value: 50, label: "50歳" },
								{ value: 55, label: "55歳" },
								{ value: 60, label: "60歳" },
								{ value: 65, label: "65歳" },
							]}
							toOptions={[
								{ value: 19, label: "19歳" },
								{ value: 24, label: "24歳" },
								{ value: 29, label: "29歳" },
								{ value: 34, label: "34歳" },
								{ value: 39, label: "39歳" },
								{ value: 44, label: "44歳" },
								{ value: 49, label: "49歳" },
								{ value: 54, label: "54歳" },
								{ value: 59, label: "59歳" },
								{ value: 64, label: "64歳" },
								{ value: 69, label: "69歳" },
							]}
							onChange={(value) => onChange("age", value)}
							value={strategyGroup.age}
							errors={
								errors && "age" in errors && typeof errors["age"] === "object"
									? (errors["age"] as ErrorObject)
									: undefined
							}
						/>
					</div>
				</FormGroup>
			</Row>
			<Row>
				<FormGroup as={Col} md={3}>
					{/* 今後離婚死別が別れたらMAになるので、配列のまま置いておく。 */}
					<AccordionForm
						title={strategyTargetGroupLabels.married}
						eventKey={"married"}
						defaultShow={!!strategyGroup.married && strategyGroup.married.length !== 0}
					>
						<Radios
							checkOff
							name={"married"}
							checked={strategyGroup.married && strategyGroup.married.length ? strategyGroup.married[0] : undefined}
							choices={marriedOptions}
							onChange={(value) => onChange("married", value === undefined ? [] : [value])}
						/>
					</AccordionForm>
				</FormGroup>
				<FormGroup as={Col} md={4}>
					<AccordionForm
						title={strategyTargetGroupLabels.children}
						eventKey={"children"}
						defaultShow={!!strategyGroup.children && strategyGroup.children.length !== 0}
					>
						<Checkboxes
							name={"children"}
							checkeds={strategyGroup.children}
							choices={childrenOptions}
							onChange={(values) => onChange("children", values)}
						/>
					</AccordionForm>
				</FormGroup>
				<FormGroup as={Col} md={3}>
					<AccordionForm
						title={strategyTargetGroupLabels.occupation}
						eventKey={"occupation"}
						defaultShow={!!strategyGroup.occupation && strategyGroup.occupation.length !== 0}
					>
						<Checkboxes
							name={"occupation"}
							checkeds={strategyGroup.occupation || []}
							choices={occupationOptions}
							onChange={(value) => onChange("occupation", value)}
						/>
					</AccordionForm>
				</FormGroup>
			</Row>
			<Row>
				<FormGroup as={Col} md={6}>
					<AccordionForm
						title={strategyTargetGroupLabels.skinAttribute}
						eventKey={"skinAttribute"}
						defaultShow={!!strategyGroup.skinAttribute && strategyGroup.skinAttribute.length !== 0}
					>
						<Checkboxes
							name={"skinAttribute"}
							checkeds={strategyGroup.skinAttribute}
							choices={skinAttributeExcludeOtherOptions as Choice[]}
							onChange={(values) => onChange("skinAttribute", values)}
						/>
					</AccordionForm>
				</FormGroup>
			</Row>
			<Row>
				<FormGroup as={Col} md={6}>
					<AccordionForm
						title={strategyTargetGroupLabels.sensitiveSkin}
						eventKey={"sensitiveSkin"}
						defaultShow={!!strategyGroup.sensitiveSkin && strategyGroup.sensitiveSkin.length !== 0}
					>
						<Checkboxes
							name={"sensitiveSkin"}
							checkeds={strategyGroup.sensitiveSkin || []}
							choices={sensitiveSkinOptions as Choice[]}
							onChange={(value) => onChange("sensitiveSkin", value)}
							// exclusive={[sensitiveSkinOptions[sensitiveSkinOptions.length - 1].value]}
						/>
					</AccordionForm>
				</FormGroup>
			</Row>
			<SensitiveSkinFrequencyCheckboxes strategyGroup={strategyGroup} onChange={onChange} />
			<SkinProblemFrequencyCheckboxes onChange={onChange} strategyGroup={strategyGroup} />
			<CategoryForm
				gender={gender}
				categories={categories}
				strategyGroup={strategyGroup}
				onChange={onChange}
				errors={errors}
				surveyChoiceOptions={surveyChoiceOptions}
			/>
			<NonPurchaseCategoryConfigForm
				strategyGroup={strategyGroup}
				onChange={onChange}
				errors={errors}
				surveyChoiceOptions={surveyChoiceOptions}
			/>
			<Row style={{ marginTop: "10px", fontSize: "1.1em" }}>
				<Col md={12}>
					以下項目は「過去1年購入&使用カテゴリ」を選択すると設定可能です。
					<hr />
				</Col>
			</Row>
			<Row>
				<FormGroup as={Col}>
					<AccordionForm
						title={strategyGroupCategoryLabels.price}
						eventKey={"price"}
						defaultShow={strategyGroup.category.some((cate) => !!cate.price && (cate.price.from || cate.price.to))}
					>
						<Row style={{ paddingLeft: "15px" }}>
							<Col md={12}>上限を設定しない場合は、上限の価格帯を選択しないでください。</Col>
							{strategyGroup.category.map((category, index) => (
								<Col md={4} key={`price-category-${category.surveyChoice}`} style={{ paddingBottom: "1em" }}>
									{surveyChoiceMap.get(category.surveyChoice)}
									<RangeSelect
										fromOptions={fromPriceOptions}
										toOptions={toPriceOptions}
										value={(category.price as RangeValue) || { from: undefined, to: undefined }}
										onChange={(v) =>
											onChangeStrategyGroupCategory(category.surveyChoice, "price", !v.from && !v.to ? undefined : v)
										}
										errors={
											errors &&
											"category" in errors &&
											typeof errors["category"] === "object" &&
											index in errors["category"] &&
											typeof errors["category"][index] === "object" &&
											"price" in (errors["category"][index] as ErrorObject) &&
											typeof (errors["category"][index] as ErrorObject)["price"] === "object"
												? ((errors["category"][index] as ErrorObject)["price"] as ErrorObject)
												: undefined
										}
									/>
								</Col>
							))}
						</Row>
					</AccordionForm>
				</FormGroup>
			</Row>
			<Row>
				<FormGroup as={Col} md={10}>
					<AccordionForm
						title={strategyTargetGroupLabels.channel}
						eventKey={"chanel"}
						defaultShow={!!strategyGroup.channel && strategyGroup.channel.length !== 0}
					>
						{strategyGroup.category.length ? (
							<Checkboxes
								name={"channel"}
								choices={channelOptions as Choice[]}
								checkeds={strategyGroup.channel}
								onChange={(v) => onChange("channel", v)}
							/>
						) : (
							<></>
						)}
					</AccordionForm>
				</FormGroup>
			</Row>
			<Row>
				<FormGroup as={Col}>
					<AccordionForm
						title={strategyGroupCategoryLabels.beautyType}
						eventKey={"beautifulSkinType"}
						defaultShow={strategyGroup.category.some((cate) => !!cate.beautyType && cate.beautyType.length !== 0)}
					>
						<Row style={{ paddingLeft: "15px" }}>
							{skincateCategoryGroups.map((category) => (
								<Col md={3} key={`beautyType-category-${category.surveyChoice}`} style={{ paddingBottom: "1em" }}>
									{surveyChoiceMap.get(category.surveyChoice)}
									<Checkboxes
										name={`${category}-beautyType`}
										choices={beautyTypeOptions as Choice[]}
										checkeds={category.beautyType || []}
										onChange={(v) => onChangeStrategyGroupCategory(category.surveyChoice, "beautyType", v)}
									/>
								</Col>
							))}
						</Row>
					</AccordionForm>
				</FormGroup>
			</Row>
			{/* face wash or cleansing */}
			<Row>
				<FormGroup as={Col} md={5}>
					<AccordionForm
						title={strategyTargetGroupLabels.facewashFunction}
						eventKey={"facewashFunction"}
						defaultShow={!!strategyGroup.facewashFunction}
					>
						{filterFacewashFunctionOptions ? (
							<Radios
								name={"facewashFunction"}
								checked={facewashFunctionChecked}
								// その他は除外
								choices={filterFacewashFunctionOptions}
								onChange={(value) => onChange("facewashFunction", value)}
							/>
						) : (
							<></>
						)}
					</AccordionForm>
				</FormGroup>
			</Row>
			{/* liquid foundation type */}
			<FoundationTypeCheckboxes strategyGroup={strategyGroup} onChange={onChange} />
			<LipstickTypeCheckboxes strategyGroup={strategyGroup} onChange={onChange} />
			<EyeshadowTypeCheckboxes strategyGroup={strategyGroup} onChange={onChange} />
			<UsedBrandForms
				strategyGroup={strategyGroup}
				onChangeStrategyGroupCategory={onChangeStrategyGroupCategory}
				categoryMap={categoryMap}
				surveyChoiceMap={surveyChoiceMap}
			/>
			<UnusedBrandForms
				strategyGroup={strategyGroup}
				onChangeStrategyGroupCategory={onChangeStrategyGroupCategory}
				categoryMap={categoryMap}
				surveyChoiceMap={surveyChoiceMap}
			/>
			<GypsyForms onChange={onChange} strategyGroup={strategyGroup} surveyChoiceOptions={surveyChoiceOptions} />
			<AestheticMedicineExperienceCheckboxes value={strategyGroup.aestheticMedicineExperience} onChange={onChange} />
		</FormArea>
	);
});
