import * as React from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type { GetAdCopyQuotasResponse } from "../../../../../../server/types/request/ad-copy/quota";
import { AdCopyQuotaPage } from "../../../../components/pages/ad-copy/job/quota";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/job/quota";

export const AdCopyQuotaContainer = (_account: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { activity, info, loading, quotas } = state;

	const { jobId } = useParams<{ jobId: string }>();

	const [isCMI, setIsCMI] = React.useState(false);

	React.useEffect(() => {
		get<GetAdCopyQuotasResponse>(`${adCopyEndpoint.quota}/${jobId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "setQuotas" });

				setIsCMI(response.data.mode === "admin");
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} titleLabel="取得処理" />

			{activity && <AdCopyQuotaPage activity={activity} isCMI={isCMI} quotas={quotas} />}
		</>
	);
};
