import * as React from "react";
import styled from "styled-components";

import type { OutputTableData } from "../../../../../../../server/models/ad-copy/output";
import { AdCopyReportTitle } from "../parts/report-title";
import { OutputTbody } from "./output-tbody";
import { OutputThead } from "./output-thead";

const StyledTable = styled.table`
	td,
	th {
		border: 1px dotted #888;
		padding: 3px;
	}

	td {
		min-width: 6rem;
	}
`;

type Props = {
	simulations: OutputTableData | undefined;
	differentiationAndONS: OutputTableData;
};

export const AdCopyResultSummary = React.memo((props: Props) => {
	const { simulations, differentiationAndONS } = props;

	return (
		<>
			<AdCopyReportTitle>結果サマリー</AdCopyReportTitle>

			<StyledTable>
				{/* 需要予測 */}
				{simulations != null && (
					<>
						<OutputThead data={simulations.tHead} />

						{simulations.tBodies.map((tBody, i) => (
							<OutputTbody key={i} data={tBody} />
						))}
					</>
				)}

				{/* 差別性等 */}
				<OutputThead data={differentiationAndONS.tHead} />

				{differentiationAndONS.tBodies.map((tBody, i) => (
					<OutputTbody key={i} data={tBody} />
				))}
			</StyledTable>
		</>
	);
});
