/**
 * クロス集計画面で表示する軸名
 */
/**
 * 軸の順番は、クロス集計画面での表示順に依存するため、変更しないこと
 */
export const strategyTargetAxisNameMap = {
	all: "全体",
	purchaseIntender: "購入意向者",
	nonPurchaseIntender: "非購入意向者",
} as const;
export type StrategyAxisName = keyof typeof strategyTargetAxisNameMap;
export const strategyTargetAxisNames = Object.keys(strategyTargetAxisNameMap) as StrategyAxisName[];

export const strategyMarketMavenAxisNameMap = {
	all: "全体",
} as const;

export type StrategyMarketMavenAxisName = keyof typeof strategyMarketMavenAxisNameMap;
export const strategyMarketMavenAxisName = Object.keys(strategyMarketMavenAxisNameMap) as StrategyMarketMavenAxisName[];

export const brandUserAxisNameMap = {
	all: "全体",
} as const;

export type BrandUserAxisName = keyof typeof brandUserAxisNameMap;
export const brandUserAxisNames = Object.keys(brandUserAxisNameMap) as BrandUserAxisName[];

/**
 * カテゴリユーザーは、集計軸に含まないが、需要予測に使うため、ここに定義
 */
export const categoryUserAxisNameMap = {
	all: "全体",
};

export type CategoryUserAxisName = keyof typeof categoryUserAxisNameMap;
export const categoryUserAxisNames = Object.keys(categoryUserAxisNameMap) as CategoryUserAxisName[];

/**
 * 需要予測に使う軸名
 *
 * ブランドユーザー割付アリの場合
 */
export const demandSegmentWithBrandUserAxisNameMap = {
	brandUserStrategyTarget: "ブランドユーザ・戦略ターゲットG該当者",
	brandUserNonStrategyTarget: "ブランドユーザ・戦略ターゲットG非該当者",
	brandNonUserStrategyTarget: "ブランド・ノンユーザ・戦略ターゲットG該当者",
	brandNonUserNonStrategyTarget: "ブランド・ノンユーザ・戦略ターゲットG非該当者",
} as const;

export type DemandSegmentWithBrandUserAxisName = keyof typeof demandSegmentWithBrandUserAxisNameMap;

export const demandSegmentWithBrandUserAxisNames = Object.keys(
	demandSegmentWithBrandUserAxisNameMap,
) as DemandSegmentWithBrandUserAxisName[];

/**
 * ブランドユーザー割付なしの場合、カテゴリREPユーザーを使う
 */
export const demandSegmentWithoutBrandUserAxisNameMap = {
	categoryUserStrategyTarget: "一般サンプルにおける戦略ターゲットG該当者",
	categoryUserNonStrategyTarget: "一般サンプルにおける戦略ターゲットG非該当者",
} as const;
export type DemandSegmentWithoutBrandUserAxisName = keyof typeof demandSegmentWithoutBrandUserAxisNameMap;
export const demandSegmentWithoutBrandUserAxisNames = Object.keys(
	demandSegmentWithoutBrandUserAxisNameMap,
) as DemandSegmentWithoutBrandUserAxisName[];

export type DemandSegmentAxisNameType = DemandSegmentWithBrandUserAxisName | DemandSegmentWithoutBrandUserAxisName;

export type DemandSegmentWithStrategyTarget = Extract<
	DemandSegmentAxisNameType,
	"brandUserStrategyTarget" | "brandUserNonStrategyTarget" | "categoryUserStrategyTarget"
>;

export type DemandSegmentWithoutStrategyTarget = Exclude<DemandSegmentAxisNameType, DemandSegmentWithStrategyTarget>;
